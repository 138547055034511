$primaryColor: #0090FF;
$white: #FFFFFF;
$gray: #9c9c9c;
$gray25: #9c9c9c;
$gray35: #595959;
$gray44: #707070;
$gray74: #BDBDBD;
$black: #000000;
$error: #EC2020;
$red239: #EF4C4C;
$mint: #94D4E9;
$gray23: #D8D7D8;
$grayOpacity50: rgba(156, 156, 156, 0.50);
$aliceBlue: #E7F3FE;
$lightGray: #999A9E;
$vividOrange: #FFBB03;
$draftColor: #9100FF;
$approvedColor: #56C96D;
$rejectColor: #EF4C4C;
$paleBlue: #D4E2EF;
$darkGray: #2A2A2A;
