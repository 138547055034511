@import "src/styles/colors";

.profile-header {
  &__avatar {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__text {
    font-size: 12px;
    color: $gray35;
    &--user {
      color: $primaryColor;
    }
  }
  &__menu-icon {
    margin-left: 10px;
    cursor: pointer;
  }

  &__notification {
    margin-left: 10px;
    margin-right: 15px;
  }

  &__number {
    text-align: center;
    position: absolute;
    top: -9px;
    right: -6px;
    background: $primaryColor;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 9px;
    color: $white;
  }
}


@media (max-width: 540px) {
  .profile-header {
    &__text {
      display: none;
    }
  }
}
