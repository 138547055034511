@import "colors";

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 15px;
}

.ant-layout {
  background-color: $white !important;
}

.ant-layout-content {
  //height: 100vh;/
}

.ant-input {
  height: 40px;
  padding: 4px 20px;
  background: transparent;
  border-color: $gray25;
  border-radius: 6px !important;
  color: $gray35;
}
.ant-input::placeholder {
  color: $gray;
}
.ant-select-selector {
  color: $gray35 !important;
}

.ant-input-affix-wrapper {
  padding: 0 20px 0 0;
  .ant-input {
    height: 40px;
    padding: 4px 2px 4px 20px !important;
  }
}

.ant-btn-primary {
  background: $primaryColor;
  height: 40px;
  min-width: 154px;
}

.ant-btn-default {
  color: $primaryColor;
  height: 40px;
  min-width: 154px;
  border-color: $primaryColor;
}

.ant-form-item-explain-error {
  background: $red239;
  color: $white !important;
  font-size: .75rem;
  border-radius: 6px;
  text-align: start;
  padding: .5rem 1.5rem;
  margin: .5rem 0;
}

.ant-form-show-help-item-leave {
  display: none !important;

}
.ant-form-show-help-item-appear, .ant-form-show-help-item-enter {
  height: 34.84px !important;
}

//.ant-form-item-explain-error::after {
//  content: "";
//  position:absolute;
//  left: 10%;
//  width: 0;
//  top: 32.5%;
//  height: 0;
//  border: 10px solid transparent;
//  border-bottom-color: $red239;
//  display: block;
//}

.ant-btn-link {
  color: $primaryColor;
}

.ant-layout-footer {
  background: $white !important;
  text-align: center;
  color: $gray !important;
  font-size: 12px !important;
}

.ant-input-password-icon {
  color: $gray !important;
  font-size: 15.85px !important;
}

.ant-layout-header {
  position: sticky !important;
  min-height: 136px;
  background: $white !important;
  width: 100%;
  top: 0;
  z-index: 5;
}

.ant-form-item-label {
  padding: 0 0 8px 20px !important;

  label {
    color: $gray35 !important;
    font-size: 12px !important;
  }
}

.ant-form-item-control-input-content {
  //border: .5px solid $gray35;
  border-radius: 6px;
}

.ant-input-affix-wrapper {
  border-color: $gray25;
  border-width: 0.03125rem !important;
}

.custom-content {
  padding-inline: 11% !important;
}

.ant-layout-header {
  padding-inline: 11% !important;
}


.ant-select-item-option-selected {
  background: $aliceBlue !important;
  font-weight: normal !important;
}

.ant-select-item {
  color: $gray35 !important;
}

.ant-input-suffix {
  margin-inline-start: 20px !important;
}

.ant-input-number-input {
  height: 40px !important;
  padding: 0 20px !important;
  color: $gray35 !important;
}
.ant-input-number-input::placeholder {
  color: $gray !important;
}

.ant-input-number {
  width: 100% !important;
  border-color: $gray25;
}

// Custom Tooltip
.ant-tooltip-inner {
  background: $white !important;
  color: $gray35 !important;
  padding: 14px !important;
  font-size: 12px;
}

.ant-tooltip-arrow::before{
  background-color: $white !important;
}

.ant-breadcrumb {
  color: $gray35 !important;
  font-size: 10px !important;
}

.ant-pagination-item-active {
  background: $primaryColor !important;
  border-color: $primaryColor !important;
  a {
    color: $white !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner  {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.ant-dropdown-menu {
  padding: 25px 40px !important;
  width: 235px !important;
  border-radius: 16px !important;
  top: 28px !important;
}

.ant-dropdown-menu-title-content {
  color: $gray35 !important;
}

.ant-dropdown-menu-item {
  margin-inline: -40px !important;
  padding-inline: 40px !important;
  border-radius: 0 !important;
  height: 34px !important;
  margin-block: 10px !important;
}

.ant-dropdown-menu-item-divider {
  margin: 12px 0 !important;
}

.ant-dropdown-menu-item-active {
  background: $aliceBlue !important;
}

.ant-checkbox {
  color: $gray35 !important;
  margin-bottom: 4px !important;
}

.ant-checkbox-inner {
  border-color: $gray35 !important;

  width: 20px !important;
  height: 20px !important;

  &::after {
    transform: rotate(45deg) scale(1.2) translate(-35%,-60%) !important;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s !important;
  }
}
.ant-checkbox-wrapper {
  color: $gray35 !important;
  height: 40px !important;
  align-content: center;
  display: flex;
  align-items: center;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.ant-tag {
  border-color: $gray44;
  background: $white;
  border-radius: 16px;
  height: 36px;
  line-height: 31px !important;
  padding: 0 20px;
  font-size: 13px;
  color: $gray35;
  cursor: default;

  &--checked {
    border-color: $primaryColor;
    color: $primaryColor;
  }
}

.ant-rate-star-full {
  path {
    fill: $vividOrange !important;
  }
}

.ant-btn-text {
  font-size: 12px !important;
  color: $gray35 !important;
  padding: 4px 8px !important;
}

.ant-image-preview-img {
  height: 60%;
}

.ant-form-item-tooltip {
  color: $primaryColor !important;
  margin-left: .5rem !important;
}

.ant-typography {
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .custom-content {
    padding-inline: 6% !important;
  }
  .ant-layout-header {
    padding-inline: 6% !important;
  }
}
