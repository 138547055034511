@import "src/styles/colors";

.header {
  min-height: 130px;
  border-bottom: .5px solid $gray23;
  margin-inline: -14%;
  padding-inline: 14%;

  &__profile {
    margin-left: auto;
  }

  &__logo {
    cursor: pointer;
    height: 150px;
    &--responsive {
      height: 75px;
      width: 75px;
    }
  }
}

.antd-header {
  &--sub-header {
    min-height: 207px !important;
  }
  &--sub-menu {
    min-height: 80px !important;
  }
}
