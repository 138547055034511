@import "../../../styles/colors";

.home-header {
  height: 56px;
  max-height: 56px;
  border-bottom: 2px solid $primaryColor;
  margin-inline: -14%;
  padding-inline: 14%;

  &__item {
    color: $gray35;
    font-size: 14px;
    min-width: 168px;
    overflow: hidden;
    text-align: center;
    height: 56px !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__button {
    height: 36px;
    display: flex;
    align-items: center;
    min-width: 168px;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: $primaryColor;
      color: $white;
    }

    &--selected {
      background: $primaryColor;
      color: $white;
    }
  }

  &__divider {
    color: $primaryColor;
    font-size: 16px;
    font-weight: 100 !important;
  }

  &__arrow {
    cursor: pointer;
    &--left {
      transform: rotate(90deg);
    }

    &--right {
      transform: rotate(270deg);
      margin-left: auto;
    }
  }

  &__col {
    height: 56px !important;
    display: flex;
    align-items: center;
  }
}
.scroll-images {
  width: 100%;
  height: 56px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  align-items: center;
}
