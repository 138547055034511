@import "../../styles/colors";

.lang-picker{
  margin-top: 0 !important;

  .ant-select-item {
    padding-inline: 12px !important;
  }

  .ant-select-selection-item {
    color: $primaryColor;
    font-size: 12px;
    margin-right: 10px !important;
  }

  .icon{
    margin-bottom: .25rem;
  }

  .ant-select {
    margin-right: -10px !important;
  }
}

