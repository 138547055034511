@import "src/styles/colors";

$sizes: 20;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.5rem;
    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin;
    }
    .ml-#{$i} {
      margin-left: $margin;
    }
    .mr-#{$i} {
      margin-right: $margin;
    }
    .mt-#{$i} {
      margin-top: $margin;
    }
    .mb-#{$i} {
      margin-bottom: $margin;
    }
    .mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;


@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.5rem;
    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding;
    }
    .pl-#{$i} {
      padding-left: $padding;
    }
    .pr-#{$i} {
      padding-right: $padding;
    }
    .pt-#{$i} {
      padding-top: $padding;
    }
    .pb-#{$i} {
      padding-bottom: $padding;
    }
    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

@include padding-classes;

.mh-100{
  min-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer !important;
}
